import MenuContainer_ClassicComponent from '@wix/thunderbolt-elements/src/components/MenuContainer/viewer/skinComps/Classic/Classic.skin';


const MenuContainer_Classic = {
  component: MenuContainer_ClassicComponent
};


export const components = {
  ['MenuContainer_Classic']: MenuContainer_Classic
};

